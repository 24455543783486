.application-history-page
  &__row
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 20px
  &__button
    margin-left: auto!important
    display: block!important
  &__link
    text-decoration: none
  &__row
    display: flex
    align-items: center
    .application-history-page__link
      margin-left: 10px

@media print
  .application-history-page
    &__paper
      display: none
    &__row
      display: none